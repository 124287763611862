<template>
  <div class="publish-works">发布作品</div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.publish-works {
  background-color: #ffffff;
  border-radius: 5px;
}
</style>
